import * as React from 'react';
import { Dropdown } from 'wix-ui-tpa/Dropdown';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../../context/running-environment-context';
import { st, classes } from './DropDownCategories.st.css';
import { CategoriesProps } from '../Categories';

interface DropDownCategoriesProps
  extends RunningEnvironmentProps,
    CategoriesProps {}

class DropDownCategoriesComponent extends React.PureComponent<
  DropDownCategoriesProps
> {
  constructor(props: DropDownCategoriesProps) {
    super(props);
    this.onCategorySelected = this.onCategorySelected.bind(this);
  }

  onCategorySelected(newSelectedCategoryIndex) {
    this.props.onCategorySelected(newSelectedCategoryIndex);
  }

  render() {
    const { selectedCategoryIndex, categoriesItems } = this.props;
    const options = categoriesItems.map((category, index) => ({
      id: `${index}`,
      isSelectable: true,
      value: category.title,
    }));

    const dropDownProps = {
      initialSelectedId: `${selectedCategoryIndex}`,
      // mobileNativeSelect: runningEnvironment.isMobile,
      onChange: (selectedCategory) =>
        this.onCategorySelected(Number(selectedCategory.id)),
      options,
    };

    return <Dropdown {...dropDownProps} className={st(classes.root, {})} />;
  }
}

export const DropDownCategories = withRunningEnvironmentContext(
  DropDownCategoriesComponent,
);
